<template>
    <div class="m-box">
        <div class="m-title">
            <div class="m-left">
                <img src="../../assets/basedata/icon_d_title.png" width="24" />
                <span>{{mTitle}}</span>
            </div>
            <div class="m-right" v-if="extra">
                <slot name="extra"></slot>
            </div>
        </div>
        <slot name="content"></slot>
    </div>
</template>

<script>
export default {
    name: "DetailModule",
    props: ["mTitle", "extra"],
    data() {
        return {}
    }
}
</script>

<style lang="less" scoped>
.m-box {
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    > .m-title {
        height: 56px;
        padding: 0 20px;
        border-bottom: 1px solid #e4e7eb;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .m-left {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            > span {
                color: #444;
            }
            > img {
                margin-right: 10px;
            }
        }
    }
}
</style>