export const leftMenuList = [
    {
        level: "2",
        name: "全部",
        dictKey: "all",
        id: 0
    },
    {
        level: "2",
        name: "水深",
        dictKey: "depthOfWater",
        id: 1
    },
    {
        level: "2",
        name: "海浪",
        dictKey: "seaWave",
        id: 2
    },
    {
        level: "2",
        name: "水位",
        dictKey: "waterLevel",
        id: 3
    },
    {
        level: "2",
        name: "水温",
        dictKey: "waterTemperature",
        id: 4
    },
    {
        level: "2",
        name: "盐度",
        dictKey: "salinity",
        id: 5
    },
    {
        level: "2",
        name: "海流",
        dictKey: "oceanCurrent",
        id: 6
    },
    {
        level: "2",
        name: "风速",
        dictKey: "windSpeed",
        id: 7
    },
    {
        level: "2",
        name: "浊度",
        dictKey: "turbidity",
        id: 8
    },
    {
        level: "2",
        name: "叶绿素",
        dictKey: "chlorophyll",
        id: 9
    }
]
// 数据清单
export const tableData = [
    {
        date: '202207',
        sizeName: "DaChen",
        dataName: "DCN2207",
        dataFormat: ".txt",
        year: "2022",
        mouth: "7"
    },
    {
        date: '202207',
        sizeName: "XiaoChangShan",
        dataName: "xcS2207",
        dataFormat: ".txt",
        year: "2022",
        mouth: "7"
    },
    {
        date: '202207',
        sizeName: "LvSi",
        dataName: "LSI2207",
        dataFormat: ".txt",
        year: "2022",
        mouth: "7"
    },
    {
        date: '202207',
        sizeName: "ShengShan",
        dataName: "SSN2207",
        dataFormat: ".txt",
        year: "2022",
        mouth: "7"
    },
    {
        date: '202207',
        sizeName: "DaChen",
        dataName: "DCN2207",
        dataFormat: ".txt",
        year: "2022",
        mouth: "7"
    },
    {
        date: '202207',
        sizeName: "XiaoChangShan",
        dataName: "xcS2207",
        dataFormat: ".txt",
        year: "2022",
        mouth: "7"
    },
    {
        date: '202207',
        sizeName: "LvSi",
        dataName: "LSI2207",
        dataFormat: ".txt",
        year: "2022",
        mouth: "7"
    },
    {
        date: '202207',
        sizeName: "ShengShan",
        dataName: "SSN2207",
        dataFormat: ".txt",
        year: "2022",
        mouth: "7"
    },
    {
        date: '202207',
        sizeName: "LvSi",
        dataName: "LSI2207",
        dataFormat: ".txt",
        year: "2022",
        mouth: "7"
    },
    {
        date: '202207',
        sizeName: "ShengShan",
        dataName: "SSN2207",
        dataFormat: ".txt",
        year: "2022",
        mouth: "7"
    }
]
// 本次下载记录
export const recordData = [
    {
        user: "134****2124",
        company: "中国地质局",
        downLoadDate: "2023-01-5 19:02:13"
    },
    {
        user: "134****2124",
        company: "中国地质局",
        downLoadDate: "2023-01-5 19:02:13"
    },
    {
        user: "134****2124",
        company: "中国地质局",
        downLoadDate: "2023-01-5 19:02:13"
    },
    {
        user: "134****2124",
        company: "中国地质局",
        downLoadDate: "2023-01-5 19:02:13"
    },
    {
        user: "134****2124",
        company: "中国地质局",
        downLoadDate: "2023-01-5 19:02:13"
    },
    {
        user: "134****2124",
        company: "中国地质局",
        downLoadDate: "2023-01-5 19:02:13"
    }
]
export const baseData = [
    {
        title: "全球地形水深",
        imgUrl: require("../assets/basedata/img01.jpg"),
        des: "平均海平面为基准面，网格化水深分辨率不低于1′（1n mile（海里）≈1852m），格式为netcdf格式和ASCII格式。"
    },
    {
        title: "海图水深数据",
        imgUrl: require("../assets/basedata/img02.jpg"),
        des: "海图是公开发行用于航海、航道、工程、数模等的岸线和水深图，我们详细介绍海图数据，并提供相关服务。"
    },
    {
        title: "观测水深数据",
        imgUrl: require("../assets/basedata/img03.jpg"),
        des: "海洋工程需实测水深，单波束或多波束测量的现场水深，经潮汐校准到85高程或理论最低潮面，供大家使用。"
    },
    {
        title: "波浪和风场数据",
        imgUrl: require("../assets/basedata/img04.jpg"),
        des: '海洋站准实时数据（中国）格式是ASCII（字符）格式海洋站准实时数据（中国）格式是格式是格式是'
    },
    {
        title: "卫星遥感海表面温度数据",
        imgUrl: require("../assets/basedata/img05.jpg"),
        des: '海洋站准实时数据（中国）格式是ASCII（字符）格式海洋站准实时数据（中国）格式是格式是格式是'
    },
    {
        title: "海表面压强",
        imgUrl: require("../assets/basedata/img06.png"),
        des: '海洋站准实时数据（中国）格式是ASCII（字符）格式海洋站准实时数据（中国）格式是格式是格式是'
    },
    {
        title: "全球表层和中尺度海流图",
        imgUrl: require("../assets/basedata/img07.jpg"),
        des: '海洋站准实时数据（中国）格式是ASCII（字符）格式海洋站准实时数据（中国）格式是格式是格式是'
    },
    {
        title: "中国月平均水位延时数据",
        imgUrl: require("../assets/basedata/img08.png"),
        des: '海洋站准实时数据（中国）格式是ASCII（字符）格式海洋站准实时数据（中国）格式是格式是格式是'
    },
    {
        title: "全球海平面观测",
        imgUrl: require("../assets/basedata/img09.jpg"),
        des: '海洋站准实时数据（中国）格式是ASCII（字符）格式海洋站准实时数据（中国）格式是格式是格式是'
    },
    {
        title: "中国温盐观测数据",
        imgUrl: require("../assets/basedata/img10.jpg"),
        des: '海洋站准实时数据（中国）格式是ASCII（字符）格式海洋站准实时数据（中国）格式是格式是格式是'
    },
    {
        title: "中国月平均水位延时数据",
        imgUrl: require("../assets/basedata/img11.jpg"),
        des: '海洋站准实时数据（中国）格式是ASCII（字符）格式海洋站准实时数据（中国）格式是格式是格式是'
    }
    ,
    {
        title: "中国船舶观测数据",
        imgUrl: require("../assets/basedata/img12.jpg"),
        des: '海洋站准实时数据（中国）格式是ASCII（字符）格式海洋站准实时数据（中国）格式是格式是格式是'
    }
]

// 仪器设备菜单数据
export const equipmentMenuList = [
    {
        level: "2",
        name: "全部",
        dictKey: "",
        id: 0
    },
    {
        level: "2",
        name: "传感器",
        dictKey: "sensor",
        id: 1
    },
    {
        level: "2",
        name: "无人机库",
        dictKey: "uav",
        id: 2
    },
]

// 仪器设备列表数据
export const equipmentList = [
    {
        id: "p01",
        name: "无人机自动机库（WK_YU_JK V1.0）",
        url: [
            { httpAccessUrl: require("../assets/equipment/1.jpg") }
        ],
        type: "uav",
        typeValue: "无人机库",
        content: "可同时适配DJI御2、御3系列"
    },
    {
        id: "p02",
        name: "无人机自动机库（WK_M_JK V1.0）",
        url: [
            { httpAccessUrl: require("../assets/equipment/2.jpg") }
        ],
        type: "uav",
        typeValue: "无人机库",
        content: "可适配DJI 经纬M300、M30系列"
    },
    {
        id: "p03",
        name: "5G高端机库",
        url: [
            { httpAccessUrl: require("../assets/equipment/3.png") }
        ],
        type: "uav",
        typeValue: "无人机库",
        content: "支持5G控制飞机，更远续航，适配大疆M300和M30飞机"
    },
    {
        id: "p04",
        name: "室内无人机自主巡检一体化平台",
        url: [
            { httpAccessUrl: require("../assets/equipment/4.png") }
        ],
        type: "system",
        typeValue: "系统平台",
        content: "基于智能机库 —— 让无人机赋能百行百业"
    },
]

// 无人机产品详情
export const uavProductData = [
    {
        title: "无人机自动机库（WK_YU_JK V1.0）",
        des: "可同时适配DJI御2、御3系列",
        size: "1.1m×1.0m×0.9m",
        weight: "80kg",
        temperature: "-20 ℃~ 50℃",
        controlTemMode: "工业空调",
        imgUrl: require("../assets/equipment/s01.png"),
        list: [
            { text: "支持指令式自动机库开启及无人机放飞;" },
            { text: "支持无人机自动充电，充电时间约50分钟；" },
            { text: "支持视觉引导精准降落；" },
            { text: "满足防护等级：不低于IP54；" },
            { text: "输入电压：200-240Vac；" },
            { text: "峰值功率：400W；" },
            { text: "配备气象监测，能监测机库周围风向风力雨雪等天气情况；" },
            { text: "配备飞行控制设备，可以控制无人机、机库、无线充电模块等设备，可以将飞行数据上传至服务端；" },
        ]
    },
    {
        title: "无人机自动机库（WK_M_JK V1.0）",
        des: "可适配DJI 经纬M300、M30系列",
        size: "1.3m×1.3m×1.4m",
        weight: "200kg",
        temperature: "-20 ℃~ 50℃",
        controlTemMode: "工业空调",
        imgUrl: require("../assets/equipment/s02.png"),
        list: [
            { text: "机库防护等级：不低于IP54；" },
            { text: "充电方式：触点充电；" },
            { text: "配备气象监测，能监测机库周围风向风力雨雪等天气情况；" },
            { text: "配备监控设备，能监视机库周围情况；" },
            { text: "配备飞行控制设备，可以控制无人机、机库、充电模块等设备，可以将飞行数据上传至服务端；" },
            { text: "输入电源：200-240Vac；峰值功率：500W；" },
        ]
    },
    {
        title: "“5G高端机库”，价格面议",
        des: "支持5G控制飞机，更远续航，适配大疆M300和M30飞机",
        size: "1.3m×1.3m×1.4m",
        weight: "200kg",
        temperature: "-20 ℃~ 50℃",
        controlTemMode: "工业空调",
        imgUrl: require("../assets/equipment/s01.png"),
        list: [
            { text: "通讯方式：5G；" },
            { text: "支持指令式自动机库开启及无人机放飞；" },
            { text: "支持无人机自动充电，充电时间约50分钟；" },
            { text: "支持视觉引导精准降落；" },
            { text: "满足防护等级：不低于IP54；" },
            { text: "输入电压：200-240Vac；" },
            { text: "峰值功率：400W；" },
            { text: "配备气象监测，能监测机库周围风向风力雨雪等天气情况；" },
            { text: "配备飞行控制设备，可以控制无人机、机库等设备，可以将飞行数据上传至服务端；" },
        ]
    },
    {
        title: "室内无人机自主巡检一体化平台",
        imgUrl: require("../assets/equipment/sn-detail.jpg"),
    }
]
// 标准规范
export const standardMenuData = [
    {
        level: "5",
        name: "全部",
        dictKey: "all",
        id: 0
    },
    {
        level: "5",
        name: "海洋调查规范",
        dictKey: "depthOfWater",
        id: 1
    },
    {
        level: "5",
        name: "其他海洋规范",
        dictKey: "seaWave",
        id: 2
    },
    {
        level: "5",
        name: "海洋预报",
        dictKey: "waterLevel",
        id: 3
    },
    {
        level: "5",
        name: "水产品输出规范",
        dictKey: "waterTemperature",
        id: 4
    },
    {
        level: "5",
        name: "水运工程模拟实验技术规范",
        dictKey: "salinity",
        id: 5
    },
    {
        level: "5",
        name: "核电厂取水排水影响分析",
        dictKey: "oceanCurrent",
        id: 6
    },
    {
        level: "5",
        name: "海洋工程地形侧脸规范",
        dictKey: "windSpeed",
        id: 7
    },
]
// 标准规范列表数据
export const standardList = [
    {
        id: 0,
        dataName: "GBT-1276.2-2007-海洋调查规范-第1部分:总则",
    },
    {
        id: 1,
        dataName: "GBT-1276.2-2007-海洋调查规范-第2部分:海洋水文观测",
    },
    {
        id: 2,
        dataName: "GBT-1276.4-2007-海洋调查规范-第4部分:海洋化学要素调查",
    },
    {
        id: 3,
        dataName: "GBT-1276.9-2007-海洋调查规范-第9部分:海洋生态调查指南",
    },
    {
        id: 4,
        dataName: "GBT-17378.1-2007-海洋监测规范-第1部分:总则",
    },
    {
        id: 5,
        dataName: "GBT-17378.2-2007-海洋监测规范-第2部分:数据处理与分析质量控制",
    },
    {
        id: 6,
        dataName: "GBT-17378.3-2007-海洋监测规范-第3部分:样品采集、存储与运输",
    },
    {
        id: 7,
        dataName: "GBT-17378.4-2007-海洋监测规范-第4部分:海水分析",
    },
    {
        id: 8,
        dataName: "GBT-17378.5-2007-海洋监测规范-第5部分:沉积物分析",
    },
    {
        id: 9,
        dataName: "GBT-17378.6-2007-海洋监测规范-第6部分:生物体分析",
    }
]
// 海洋预报菜单数据
export const menuLevelList = [
    {
        title: "边缘海",
        key: "byh",
        index: '1',
        children: [
            {
                title: "南海北部",
                key: "nhbb",
                index: '11',
            },
            {
                title: "东中国海",
                key: "dzgh",
                index: '12',
            },
            {
                title: "渤黄海",
                key: "bhh",
                index: '13',
            }
        ]
    },
    {
        title: "浅海近岸",
        key: "qhja",
        index: '2',
        children: [
            {
                title: "温州近海",
                key: "wzjh",
                index: '21',
            },
            {
                title: "天津近海",
                key: "tjjh",
                index: '22',
            },
            {
                title: "湛江近海",
                key: "zjjh",
                index: '23',
            },
            {
                title: "三亚近海",
                key: "syjh",
                index: '24',
            },
            {
                title: "青岛近海",
                key: "qdjh",
                index: '25',
            },
            {
                title: "威海近海",
                key: "whjh",
                index: '26',
            }
        ]
    },
    {
        title: "港口核电风电",
        key: "gkhdfd",
        index: '3',
        children: [
            {
                title: "董家口港-1",
                key: "dj1",
                index: '31',
            },
            {
                title: "董家口港-2",
                key: "dj2",
                index: '32',
            },
            {
                title: "董家口港-3",
                key: "dj3",
                index: '33',
            },
            {
                title: "前湾港",
                key: "qwg",
                index: '34',
            },
            {
                title: "大连港",
                key: "dlg",
                index: '35',
            },
            {
                title: "岚山港",
                key: "lsg",
                index: '36',
            },
            {
                title: "日照港",
                key: "rzg",
                index: '37',
            },
            {
                title: "烟台港",
                key: "ytg",
                index: '38',
            },
            {
                title: "南山港",
                key: "nsg",
                index: '39',
            },
            {
                title: "洋浦港",
                key: "ypg",
                index: '310',
            }
        ]
    },
    {
        title: "大洋",
        key: "ocean",
        index: '4',
        children: [
            {
                title: "西北太平洋",
                key: "northwestPacificOcean",
                index: '41',
            },
            {
                title: "印度洋",
                key: "indianOcean",
                index: '42',
            },
            {
                title: "大西洋",
                key: "atlantic",
                index: '43',
            },
            {
                title: "北极",
                key: "northPole",
                index: '44',
            },
            {
                title: "南极",
                key: "southPole",
                index: '45',
            }
        ]
    },
]