<template>
    <div class="custom">
        <el-table @selection-change="handleSelectionChange" :data="tableData" :stripe="true" :height="height ? height : null" style="width: 100%" size="medium">
            <template v-for="(item,index) in cloumns">
                <el-table-column :key="index" :type="item.type ? item.type : null" :width="item.width" :label="item.label" :prop="item.prop">
                    <div v-if="item.prop == 'operate'">
                        <slot :name="item.prop" :item="tableData[index]"></slot>
                    </div>
                </el-table-column>
            </template>

        </el-table>
    </div>
</template>

<script>

export default {
    name: "Table",
    props: ["tableData", "cloumns", "height"],
    data() {
        return {
            cloumnsList: [],
            tData: []
        }
    },
    mounted() {
    },
    methods: {
        handleSelectionChange(val) {
            let data = val
            this.$emit('handleSelectionChange', data)
        }
    }
}
</script>

<style lang="less" scoped>
.custom {
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #dde3eb;
    border-bottom: none;
    /deep/ .el-table thead {
        color: #4b5669;
    }
    /deep/ .el-table th.el-table__cell {
        background-color: #f1f5f8;
    }
}
</style>