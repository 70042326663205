<template>
    <div class="page">
        <el-pagination v-if="pagination" @prev-click="prevClick" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagination.currentPage" :page-size="pagination.pageSize" layout="total,  prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>
    </div>
</template>

<script>
export default {
    name: "Page",
    props: ['pagination'],
    data() {
        return {

        }
    },
    mounted() {
    },
    methods: {
        handleSizeChange(current) {
            this.$emit('handleSizeChange', current)
        },
        handleCurrentChange(val) {
            this.$emit('handleCurrentChange', val)
        },
        prevClick(data) {
            this.$emit('prevClick', data)
        }
    }
}
</script>

<style lang="less" scoped>
.page {
    width: 100%;
    padding-bottom: 30px;
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
    /deep/ .el-pagination {
        button {
            border-radius: 5px;
            border: 1px solid #cbced3;
            color: #555;
            padding: 0;
            min-width: 28px;
        }
        .btn-prev,
        .btn-next {
            padding-right: 0;
        }
        .btn-prev {
            margin-right: 8px;
        }
        .btn-prev:disabled,
        .btn-next:disabled {
            border: 1px solid #cbced3;
            color: #d2d8e0;
        }
        .el-pager {
            li {
                min-width: 28px;
                border-radius: 5px;
                border: 1px solid #cbced3;
                color: #555;
                margin-right: 8px;
            }
            li.active {
                color: #fff;
                cursor: default;
                border-color: #1d78cd;
                background-color: #1d78cd;
            }
        }
    }
}
</style>