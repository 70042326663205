<template>
    <div class="detail">
        <div class="handleBar">
            <div class="bar">
                <div class="name">仪器设备</div>
                <div class="handle">
                    <el-button type="primary" plain @click="back"> 返回列表</el-button>
                </div>
            </div>
        </div>
        <div class="module2" v-if="uavIdList.indexOf(pId) != -1">
            <DetailModule mTitle="详情信息">
                <template v-slot:content>
                    <div class="detail">
                        <img :src="detailData.imgUrl" width="100%" />
                    </div>
                </template>
            </DetailModule>
        </div>
        <div class="module2" v-else>
            <DetailModule mTitle="详情信息">
                <template v-slot:content>
                    <div v-html="detailData"></div>
                </template>
            </DetailModule>
        </div>

    </div>
</template>

<script>
import Page from '@/components/Page'
import Table from './Table.vue'
import { uavProductData } from "@/mockData"
import DetailModule from '@/components/DetailModule'
const uavIdList = ['e60a19e3a437493e821ec2f8a13d8a8b','d615f468a0af499e97f9049b535cd92b','24f63e1a86854b8f8562863087d14c26','1ea1c0d18bff441a8a425a66caefea9e']
export default {
    name: "Detail",
    components: { DetailModule, Page, Table },
    data() {
        return {
            detailData: {},
            pId: "",
            uavIdList:uavIdList,
            html:`<h1>今天天色很好</h1>`
        }
    },
    watch: {
        detailData: function (newV, oldV) {
            return this.detailData
        }
    },
    mounted() {
        this.pId = this.$route.query.id
        switch (this.pId) {
            case 'e60a19e3a437493e821ec2f8a13d8a8b':
                this.detailData = uavProductData[0]
                break;
            case 'd615f468a0af499e97f9049b535cd92b':
                this.detailData = uavProductData[1]
                break;
            case '24f63e1a86854b8f8562863087d14c26':
                this.detailData = uavProductData[2]
                break;
            case '1ea1c0d18bff441a8a425a66caefea9e':
                this.detailData = uavProductData[3]
                break;
            default:
            this.$request("/equipment/getEquipmentDetail", {
                method: "post",
                data: {id:this.pId}
            }).then(res => {
                this.detailData = res.data.details
            })
                
        }
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        tohandle(data, type) {
            console.log(data);
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        toWebsit() {
            window.location.href = "http://sarisoft.cn"
        }
    }
}
</script>

<style lang="less" scoped>
.detail {
    .handleBar {
        width: 100%;
        background-color: #fff;
        position: absolute;
        left: 0;

        .bar {
            height: 60px;
            width: 1400px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            margin: 0 auto;
            > .name {
                font-size: 18px;
                font-weight: bold;
                color: #444;
            }
        }
    }
    .module2 {
        padding-top: 90px;
        margin-bottom: 40px;
        .detail {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 20px;
            > .left {
                width: 60%;
                margin-right: 20px;
                > .h2 {
                    font-size: 22px;
                    margin-bottom: 10px;
                }
                > .h3 {
                    font-size: 16px;
                    color: #666;
                    margin-bottom: 10px;
                }
                > .card {
                    width: 100%;
                    background-color: #f4f8ff;
                    padding: 20px;
                    padding-bottom: 0;
                    display: flex;
                    flex-wrap: wrap;
                    > .item {
                        width: calc(100% / 2);
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-bottom: 15px;
                        > img {
                            margin-right: 10px;
                        }
                        > dl {
                            > dt {
                                font-size: 16px;
                                color: #666;
                            }
                            > dd {
                                font-size: 18px;
                                color: #003b8e;
                                margin-top: 8px;
                            }
                        }
                    }
                }
                > .textList {
                    margin-top: 20px;
                    > .textItem {
                        display: flex;
                        align-items: flex-start;

                        margin-bottom: 15px;
                        > span {
                            margin-right: 10px;
                            color: #333;
                        }
                        > p {
                            color: #333;
                        }
                    }
                }
            }
            > .right {
                > img {
                    width: 100%;
                }
            }
        }
    }
}
</style>